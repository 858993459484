import { useState, useEffect, useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"
import EmojiPicker, { Theme, EmojiStyle, Emoji } from "emoji-picker-react"
import useSessionStore from "stores/session"
import useGlobalStore from "stores/global"
import useSnackbarStore from "stores/snackbar"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import useT from "utils/useT"
import { AppInterface } from "types/types"
import { MdOutlineAddReaction } from "react-icons/md"
import { useRouter } from "next/router"
import Modal from "components/modal" // Assuming you have this component
import { FaUserLock } from "node_modules/react-icons/fa"

// Update Reaction type to match new structure
type ReactionData = {
  [userId: string]: string // userId: timestamp
}

type ReactionsMap = {
  [unifiedEmoji: string]: ReactionData
}

type ReactionsProps = {
  reactions?: ReactionsMap
  trackId: number
  submitterId: string // Add submitterId to identify which submitter's reactions to use
}

const Reactions = ({
  reactions = {},
  trackId,
  submitterId,
}: ReactionsProps) => {
  const [showPicker, setShowPicker] = useState(false)
  const [lastChangedIndex, setLastChangedIndex] = useState<number | null>(null)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const t = useT()
  const userId = useSessionStore((state) => state.user.uid)
  const isDark = useGlobalStore((state) => state.isDark)
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const queryClient = useQueryClient()
  const pickerRef = useRef<HTMLDivElement>(null)
  const plusButtonRef = useRef<HTMLButtonElement>(null)
  const router = useRouter()

  // Convert reaction object to array for rendering
  const reactionsArray = Object.entries(reactions || {})
    .map(([unifiedEmoji, users]) => ({
      unifiedEmoji,
      users: Object.keys(users || {}),
      count: Object.keys(users || {}).length,
    }))
    .sort((a, b) => b.count - a.count)

  const animatingReactions =
    lastChangedIndex !== null ? reactionsArray.slice(lastChangedIndex) : []

  const { mutate: addReaction } = useMutation({
    mutationFn: async ({ unifiedEmoji }: { unifiedEmoji: string }) => {
      const response = await fetch("/api/reactToPost", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          unifiedEmoji,
          trackId,
          userId,
          submitterId, // Pass submitterId to the API
        }),
      })

      // Check if the response is not OK (not 2xx status code)
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw new Error(errorData.message || `API error: ${response.status}`)
      }

      return response.json()
    },
    onMutate: async ({ unifiedEmoji }) => {
      // Cancel any outgoing refetches (include the single app query)
      await queryClient.cancelQueries({ queryKey: ["getApps"] })
      await queryClient.cancelQueries({ queryKey: ["getGames"] })
      await queryClient.cancelQueries({ queryKey: ["getAll"] })
      await queryClient.cancelQueries({
        queryKey: ["fetchApp", String(trackId)],
      })

      // Snapshot the previous value
      const previousApps = queryClient.getQueryData(["getApps"])
      const previousGames = queryClient.getQueryData(["getGames"])
      const previousAll = queryClient.getQueryData(["getAll"])
      const previousApp = queryClient.getQueryData([
        "fetchApp",
        String(trackId),
      ])

      // Optimistically update the cache for infinite queries
      const updateCache = (oldData: any) => {
        if (!oldData || !oldData.pages || !oldData.pages.length) return oldData

        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            apps: page.apps.map((app: AppInterface) => {
              if (app.trackId !== trackId) return app

              // Clone the app
              const updatedApp = { ...app }

              // Find the submitter index
              const submitterIndex = updatedApp.submitters?.findIndex(
                (submitter) => submitter.uid === submitterId,
              )

              if (submitterIndex === undefined || submitterIndex === -1) {
                return app // No matching submitter found
              }

              // Clone the submitters array to avoid direct mutation
              const updatedSubmitters = [...(updatedApp.submitters || [])]

              // Get the specific submitter
              const submitter = { ...updatedSubmitters[submitterIndex] }

              // Initialize reactions if not exist
              const submitterReactions = { ...(submitter.reactions || {}) }

              // Check if user already reacted with this emoji
              const hasReacted = submitterReactions[unifiedEmoji]?.[userId]

              if (hasReacted) {
                // Remove reaction if it exists
                const { [userId]: _, ...remainingUsers } =
                  submitterReactions[unifiedEmoji]

                // If this was the last user for this emoji, remove the emoji entry
                if (Object.keys(remainingUsers).length === 0) {
                  const { [unifiedEmoji]: __, ...remainingEmojis } =
                    submitterReactions
                  submitter.reactions = remainingEmojis
                } else {
                  // Otherwise just update the users for this emoji
                  submitter.reactions = {
                    ...submitterReactions,
                    [unifiedEmoji]: remainingUsers,
                  }
                }
              } else {
                // Add new reaction
                submitter.reactions = {
                  ...submitterReactions,
                  [unifiedEmoji]: {
                    ...(submitterReactions[unifiedEmoji] || {}),
                    [userId]: new Date().toISOString(),
                  },
                }
              }

              // Update the submitter in the array
              updatedSubmitters[submitterIndex] = submitter

              // Return updated app with new submitters array
              return {
                ...updatedApp,
                submitters: updatedSubmitters,
              }
            }),
          })),
        }
      }

      // Update all relevant infinite queries
      queryClient.setQueryData(["getApps"], updateCache)
      queryClient.setQueryData(["getGames"], updateCache)
      queryClient.setQueryData(["getAll"], updateCache)

      // Optimistically update the single app query
      const singleAppData = queryClient.getQueryData<AppInterface>([
        "fetchApp",
        String(trackId),
      ])

      if (singleAppData) {
        queryClient.setQueryData(
          ["fetchApp", String(trackId)],
          (app: AppInterface | undefined) => {
            if (!app) return app

            // Clone the app
            const updatedApp = { ...app }

            // Find the submitter index
            const submitterIndex = updatedApp.submitters?.findIndex(
              (submitter) => submitter.uid === submitterId,
            )

            if (submitterIndex === undefined || submitterIndex === -1) {
              return app // No matching submitter found
            }

            // Clone the submitters array to avoid direct mutation
            const updatedSubmitters = [...(updatedApp.submitters || [])]

            // Get the specific submitter
            const submitter = { ...updatedSubmitters[submitterIndex] }

            // Initialize reactions if not exist
            const submitterReactions = { ...(submitter.reactions || {}) }

            // Check if user already reacted with this emoji
            const hasReacted = submitterReactions[unifiedEmoji]?.[userId]

            if (hasReacted) {
              // Remove reaction if it exists
              const { [userId]: _, ...remainingUsers } =
                submitterReactions[unifiedEmoji]

              // If this was the last user for this emoji, remove the emoji entry
              if (Object.keys(remainingUsers).length === 0) {
                const { [unifiedEmoji]: __, ...remainingEmojis } =
                  submitterReactions
                submitter.reactions = remainingEmojis
              } else {
                // Otherwise just update the users for this emoji
                submitter.reactions = {
                  ...submitterReactions,
                  [unifiedEmoji]: remainingUsers,
                }
              }
            } else {
              // Add new reaction
              submitter.reactions = {
                ...submitterReactions,
                [unifiedEmoji]: {
                  ...(submitterReactions[unifiedEmoji] || {}),
                  [userId]: new Date().toISOString(),
                },
              }
            }

            // Update the submitter in the array
            updatedSubmitters[submitterIndex] = submitter

            // Return updated app with new submitters array
            return {
              ...updatedApp,
              submitters: updatedSubmitters,
            }
          },
        )
      } else {
        // If query data isn't in cache yet, we need to add debug logging
        console.log("Single app data not found in cache for trackId:", trackId)
      }

      // Return previous data for potential rollback
      return { previousApps, previousGames, previousAll, previousApp }
    },
    onError: (_err, _vars, context) => {
      // If mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(["getApps"], context?.previousApps)
      queryClient.setQueryData(["getGames"], context?.previousGames)
      queryClient.setQueryData(["getAll"], context?.previousAll)

      // Also roll back the single app query
      if (context?.previousApp) {
        queryClient.setQueryData(
          ["fetchApp", String(trackId)],
          context.previousApp,
        )
      }

      console.error(_err)

      toggleToast({
        color: "red",
        text: t.feed.errorReacting,
      })
    },
    onSettled: () => {
      // Invalidate queries to refetch latest data
      queryClient.invalidateQueries({ queryKey: ["getApps"] })
      queryClient.invalidateQueries({ queryKey: ["getGames"] })
      queryClient.invalidateQueries({ queryKey: ["getAll"] })

      // Also invalidate the single app query
      queryClient.invalidateQueries({ queryKey: ["fetchApp", String(trackId)] })
    },
  })

  const handleEmojiClick = (emojiData: any) => {
    handleReaction(emojiData.unified)
    setShowPicker(false)
  }

  const handleReaction = (unifiedEmoji: string, index?: number) => {
    addReaction(
      { unifiedEmoji },
      {
        onSuccess: () => {
          if (index !== undefined) {
            setLastChangedIndex(index)
          }
        },
      },
    )
  }

  // Function to check if user can react or need to show login modal
  const handleReactionClick = (unifiedEmoji: string, index?: number) => {
    if (!userId) {
      setShowLoginModal(true)
      return
    }

    handleReaction(unifiedEmoji, index)
  }

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(e.target as Node) &&
        plusButtonRef.current &&
        !plusButtonRef.current.contains(e.target as Node)
      ) {
        setShowPicker(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  // Add default heart emoji with like text if there's no reaction yet
  const defaultEmoji = {
    unifiedEmoji: "2764-fe0f", // Heart emoji unifiedEmoji code
    text: t.feed.like,
  }
  const showDefaults = reactionsArray.length === 0

  return (
    <div className="position-relative" onClick={(e) => e.stopPropagation()}>
      <div
        className={`d-flex align-items-center flex-wrap gap-2 ${
          reactionsArray.length > 0 ? "mb-3" : ""
        }`}
        style={{
          margin: "0px 16px 16px",
          position: reactionsArray.length === 0 ? "relative" : "static",
        }}
      >
        <AnimatePresence mode="popLayout">
          {reactionsArray.map((item, i) => {
            const myReaction = item.users.includes(userId)

            return (
              <motion.div
                key={`${trackId}-${submitterId}-${item.unifiedEmoji}`}
                layout
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{
                  opacity: { duration: 0.2 },
                  scale: {
                    type: "spring",
                    stiffness: 500,
                    damping: 25,
                  },
                  layout: {
                    type: "spring",
                    stiffness: 500,
                    damping: 25,
                    duration: 0.3,
                  },
                }}
                style={{ position: "relative" }}
              >
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  transition={{ duration: 0.1 }}
                  onClick={() => handleReactionClick(item.unifiedEmoji, i)}
                  className={`react-button ${myReaction ? "bg-highlight" : ""}`}
                >
                  <span className="d-flex align-items-center">
                    <Emoji unified={item.unifiedEmoji} size={20} />
                  </span>
                  {item.count}
                </motion.button>
              </motion.div>
            )
          })}

          {/* Default heart reaction if none exist */}
          {showDefaults && (
            <motion.div
              key={`default-heart-container-${submitterId}`}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  type: "spring",
                  stiffness: 500,
                  damping: 25,
                },
                layout: {
                  type: "spring",
                  stiffness: 500,
                  damping: 25,
                  duration: 0.3,
                },
              }}
              style={{ position: "relative" }}
            >
              <motion.button
                key={`default-heart-${submitterId}`}
                whileTap={{ scale: 0.9 }}
                transition={{ duration: 0.1 }}
                onClick={() => handleReactionClick(defaultEmoji.unifiedEmoji)}
                className="react-button"
              >
                <Emoji unified={defaultEmoji.unifiedEmoji} size={20} />
                {defaultEmoji.text}
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          key={`add-reaction-container-${submitterId}`}
          layout
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            opacity: { duration: 0.2 },
            scale: {
              type: "spring",
              stiffness: 500,
              damping: 25,
            },
            layout: {
              type: "spring",
              stiffness: 500,
              damping: 25,
              duration: 0.3,
            },
          }}
          style={{ position: "relative" }}
        >
          <motion.button
            ref={plusButtonRef}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.1 }}
            onClick={() =>
              userId ? setShowPicker(!showPicker) : setShowLoginModal(true)
            }
            className="react-button"
            type="button"
            tabIndex={-1}
            aria-label="Add reaction"
          >
            <MdOutlineAddReaction size={24} />
          </motion.button>
        </motion.div>
      </div>

      {showPicker && (
        <div
          ref={pickerRef}
          className="position-absolute z-index-99"
          style={{
            left: 40,
            bottom: 35,
          }}
        >
          <EmojiPicker
            theme={isDark ? Theme.DARK : Theme.LIGHT}
            emojiStyle={EmojiStyle.NATIVE}
            onEmojiClick={handleEmojiClick}
            lazyLoadEmojis={true}
            allowExpandReactions={false}
            reactions={[
              "2764-fe0f", // ❤️ Red heart
              "1f44d", // 👍 Thumbs up
              "1f44e", // 👎 Thumbs down
              "1f602", // 😂 Face with tears of joy
              "1f622", // 😢 Crying face
              "1f621", // 😡 Pouting face
              "1f60d", // 😍 Smiling face with heart-eyes
            ]}
            reactionsDefaultOpen={true}
            autoFocusSearch={false}
          />
        </div>
      )}

      <Modal
        modalOpen={showLoginModal}
        close={() => setShowLoginModal(false)}
        isCenter
      >
        <div className="p-3 pb-4">
          <div className="text-center mt-3 pt-1">
            <FaUserLock className="color-blue-light fa-4x" />
          </div>
          <h5 dir="auto" className="text-center mt-3 font-700 font-25 mb-4">
            {t.login.loginRequired}
          </h5>
          <p dir="auto" className="boxed-text-l text-break">
            {t.feed.loginToReact}
          </p>
          <div className="d-flex justify-content-between">
            <button
              onClick={() => setShowLoginModal(false)}
              className="btn btn-m rounded-s text-uppercase font-900 shadow-s bg-gray-dark"
              style={{ width: "48%" }}
            >
              {t.deleteComment.cancel}
            </button>
            <button
              onClick={() => {
                setShowLoginModal(false)
                router.push("/login")
              }}
              className="btn btn-m rounded-s text-uppercase font-900 shadow-s bg-highlight"
              style={{ width: "48%" }}
            >
              {t.login.login}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Reactions
